.image-card {
    position: relative;
    overflow: hidden;
    width: 300px;
    cursor: pointer;
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    transition: filter 0.3s ease-in-out;
  }
  
  .image-card.hovered img {
    filter: brightness(50%);
  }
  
  .image-card .description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .image-card.hovered .description {
    opacity: 1;
  }
  