.carousel {
  display: flex;
  justify-content: center;
}
.carousel .slide {
  height: 70vh;
}

.carousel .slide img {
  max-height: 100%;
  width: auto;
}
.image {
  margin: 0 auto;
  height: 100%;
  width: auto;
}
