.photo-slider {
    position: relative;
    height: 500px;
    overflow: hidden;
  }
   .slieimg{

    object-fit: contain;
   }
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .slide.active {
    opacity: .8;
  }
  
  .text-overlay {
    position: absolute;
    bottom: 100px;
    left: 30px;
    color: white;
    font-weight: bold;
     background-color: rgba(0, 0, 0, 0.5); 
    padding: 10px;
  }
  

  @media screen and (max-width:648px) {
    .photo-slider {
      position: relative;
      height: 200px;
  
      overflow: hidden;
    }
    .text-overlay {
    
      bottom: 20px;


     
    }
  }